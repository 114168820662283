import './App.scss';
import logo from './images/logo.png'
import discord from './images/discord.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Unordinals logo" className="logo" />
        <a
          className="discord-link"
          href="https://discord.com/invite/unlabs"
          target="_blank"
          rel="noopener noreferrer"
        > <img src={discord} alt="discord" className="discord-icon" />
          Contact us
        </a>
      </header>
    </div>
  );
}

export default App;
